import { auth, logout } from "../components/firebase";
import '../App.css';
import '../account.css';
function Account() {
  // get user info
  const user = auth.currentUser;
  const email = user.email;
  const photoURL = user.photoURL;
  const displayName = user.displayName;
  console.log(user);

    return (
      <div className="App">
        <script src="http://localhost:3002/accountnav.js"></script>
        <header className="App-header">
          <ul class="background" onclick="return true;">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <div class="login-box">
            <h2>Dashboard</h2>
            <p>&copy; Open Domains</p>
            
          </div>
          <div class="navigation">
            <div class="user-box">
              <div class="image-box">
                <img src={photoURL} alt="avatar"/>
              </div>
              <p class="username">{displayName}</p>
            </div>
            <div class="menu-toggle"></div>
            <ul class="menu">
              <li><a href="#"><ion-icon name="person-outline"></ion-icon>Profile</a></li>
              <li><a href="#"><ion-icon name="chatbox-outline"></ion-icon>Messages</a></li>
              <li><a href="#"><ion-icon name="notifications-outline"></ion-icon>Notification</a></li>
              <li><a href="#"><ion-icon name="cog-outline"></ion-icon>Settings</a></li>
              <li><a href="#"><ion-icon name="log-out-outline"></ion-icon>Logout</a></li>
          </ul>
        </div>
        </header>
      </div>
    )
  }

export default Account;

