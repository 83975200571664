import '../App.css';
function ResetDone() {
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get("email");
    return (
      <div className="App">
        <header className="App-header">
        <ul class="background" onclick="return true;">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>  
        </ul>
        <div class="login-box">
              <h2>Password reset has been sent to {email}.</h2>
                <p>Check your email for a link to reset your password.</p>
                <p>If it doesn’t appear within a few minutes, check your spam folder.</p>
                <p>Need help? Contact us.</p>
            
              <form>
              </form>
              <p>&copy; Open Domains</p>   
            </div>
  
        </header>
      </div>
    )
  ;
}

export default ResetDone;

  