import '../App.css';
import GoogleAuth from '../components/googleauth';
function Dash() {
    return (
      <div className="App">
        <header className="App-header">
        <ul class="background" onclick="return true;">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>  
        </ul>
        <div class="login-box">
              <h2>OpenDDNS</h2>
              <form>
                <div>
                  <a href="/login">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Login
                  </a>
                  <a href="/register">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Register
                  </a>
                </div>
                <p>&copy; Open Domains</p>  
              </form>
              <button onClick={GoogleAuth()}>Sign in with Google</button>
            </div>
  
        </header>
      </div>
    )
  ;
  }
export default Dash; 