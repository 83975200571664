import '../App.css';


function ErrorFallback({ error, resetErrorBoundary }) {
    return (
        <div className="App">
        <header className="App-header">
        <ul class="background" onclick="return true;">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>  
        </ul>
        <div class="login-box">
              <h2>Error</h2>
              <div class="user-box">
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <form>
                <button onClick={resetErrorBoundary}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Try again
                    </button>
                </form>
            </div>
            </div>
            </header>
        </div>

    );
}

export default ErrorFallback;