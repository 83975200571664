import { auth, logout, login } from "../components/firebase";
import '../App.css';
import { useForm } from 'react-hook-form';
function Login() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => loginuser(data);
    return (
      <div className="App">
        <header className="App-header">
        <ul class="background" onclick="return true;">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>  
        </ul>
        <div class="login-box">
              <h2>Login</h2>
              <form onSubmit={handleSubmit(onSubmit)}>
              <div class="user-box">
                <input type="email" placeholder="email" {...register("email", {required: true})} />
              </div>
              <div class="user-box">  
                <input type="password" placeholder="password" {...register("password", {required: true, min: 3})} />
              </div>  
                <button type="submit">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Submit
                </button>
              </form>
              <button href="https://beta.openddns.org/reset">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Reset Password
                </button>
              <p>&copy; Open Domains</p>   
            </div>
  
        </header>
      </div>
    )
  ;
}
function loginuser(data) {
    var email = data.email;
    var password = data.password;
    login(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      window.location.replace('https://beta.openddns.org/account');
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert(errorMessage);
    });
}
export default Login;

  