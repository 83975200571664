import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "./firebase";

const provider = new GoogleAuthProvider();


function GoogleAuth() {
  const signInWithGoogle = () => {
    signInWithPopup(auth, provider);
  };
}

export default GoogleAuth;