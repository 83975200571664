// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, updateProfile, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDKyf9bb_PCzWG-5d7Zl5dbALKkarCG4uA",
  authDomain: "auth.openddns.org",
  projectId: "open-ddns",
  storageBucket: "open-ddns.appspot.com",
  messagingSenderId: "574724950758",
  appId: "1:574724950758:web:abacba0a630e8b4a1ec927",
  measurementId: "G-2JBNNSX91R"
};







// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const register = createUserWithEmailAndPassword;
export const login = signInWithEmailAndPassword;
export const verify = sendEmailVerification;
export const reset = sendPasswordResetEmail;
export const updatep = updateProfile;

