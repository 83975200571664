import { auth, reset } from "../components/firebase";
import '../App.css';
import { useForm } from 'react-hook-form';
function ResetPassword() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => resetuser(data);
    return (
      <div className="App">
        <header className="App-header">
        <ul class="background" onclick="return true;">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>  
        </ul>
        <div class="login-box">
              <h2>Register</h2>
              <form onSubmit={handleSubmit(onSubmit)}>
              <div class="user-box">
                <input type="email" placeholder="email" {...register("email", {required: true})} />
              </div>
                <button type="submit">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Submit
                </button>
              </form>
              <p>&copy; Open Domains</p>   
            </div>
  
        </header>
      </div>
    )
  ;
}

 
async function resetuser(data) {
    var email = data.email;
    await reset(auth, email);
    window.location.replace('https://beta.openddns.org/resetdone?email=' + email);
}
export default ResetPassword;

  