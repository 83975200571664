import logo from './logo.svg';
import './App.css';
import { useForm } from 'react-hook-form';
import { Route, Routes } from 'react-router-dom';


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth";
import Account from './routes/account';
import Dash from './routes/dash';
import Login from './routes/login';
import Register from './routes/register';
import ResetPassword from './routes/reset';
import ResetDone from './routes/resetdone';


// make a html page with 2 big buttons
// one button says "I agree"
// the other button says "I disagree"
// when the user clicks "I agree" the page should say "Thank you for agreeing"
// when the user clicks "I disagree" the page should say "Thank you for disagreeing"

function ets() {
  return (
    <div>
      <button>I agree</button>
      <button>I disagree</button>
    </div>
  );
}


function App() {
  return (
    <Routes>
      <Route path="/" element={<Dash />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/account" element={<Account />} />
      <Route path="/reset" element={<ResetPassword />} />
      <Route path="/resetdone" element={<ResetDone />} />
      <Route path="/ets" element={<ets />} />
    </Routes>
  );

}





export default App;
