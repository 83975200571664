import { auth, logout, register, verify, updatep } from "../components/firebase";
import '../App.css';
import { useForm } from 'react-hook-form';
import gravitar from 'gravatar-api';
function Register() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => registerdata(data);
    return (
      <div className="App">
        <header className="App-header">
        <ul class="background" onclick="return true;">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>  
        </ul>
        <div class="login-box">
              <h2>Register</h2>
              <form onSubmit={handleSubmit(onSubmit)}>
              <div class="user-box">
                <input type="email" placeholder="email" {...register("email", {required: true})} />
              </div>
              <div class="user-box">  
                <input type="password" placeholder="password" {...register("password", {required: true, min: 3})} />
              </div>
              <div class="user-box">
                <input type="text" placeholder="name" {...register("name", {required: true, max: 5})} /> 
              </div> 
                <button type="submit">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Submit
                </button>
              </form>
              <p>&copy; Open Domains</p>   
            </div>
  
        </header>
      </div>
    )
  ;
  }
  
  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  
  async function registerdata(data) {
    var email = data.email;
    var password = data.password;
    var name = data.name;
    register(auth, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      // get gravitar image from email
      async function getGravatar(email) {
        return gravitar.imageUrl({
          email: email,
          parameters: { "size": "100", "d": "mm" },
          secure: true
        });
      }
      console.log(getGravatar(email));
      var gravatars = getGravatar(email);
      

      updatep(auth.currentUser,{
        displayName: name,
        profileURL: gravatars
      })
      // ...
    }
    )
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert(errorMessage);
      // ..
    }
    );
    await delay(1000).then(async () => {
        verify(auth.currentUser)
        .then(() => {
          window.location.replace('https://beta.openddns.org/account');
      // Email verification sent!
      // ...
    });  
    });
  }

export default Register;  